.ant-drawer {
	&.custom-drawer {
		.ant-drawer-header.ant-drawer-header-close-only {
			.ant-drawer-header-title {
				display: block;
				text-align: right;

				.ant-drawer-close {
					margin: 0;
				}
			}
		}

		.ant-drawer-body {
			overflow: initial;
		}
	}

	.title-show-drawer {
		color: #262626;
		font-size: 16px;
		font-weight: 600;
	}

	.body-show-drawer {
		height: calc(100vh - 158px);
	}

	.footer-show-drawer {

	}
}
