.asset-type-filters {
	.border-b {
		border-bottom: 1px solid #E8E8E8;
	}

	.title-filters {
		font-size: 16px;
		font-weight: 600;
	}

	.less-filters {
		overflow: hidden;
		transition: height 0.2s ease-in-out;
	}

	.ant-form-item {
		//margin: 0 0 2px 0;

		.ant-form-item-label {
			padding: 0;

			label {
				font-size: 12px;
			}
		}
	}
}
